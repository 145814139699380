'use strict';

(function ($) {
  $(document).foundation();

  $.fn.BeerSlider = function ( options ) {
    options = options || {};
    return this.each(function() {
      new BeerSlider(this, options);
    });
  };

  //Canvas-off Elements Manipulation
  $(document).on('opened.zf.offcanvas', function () {
    $('body').addClass('canvas-off-open');
  });

  $(document).on('closed.zf.offcanvas', function () {
    $('body').removeClass('canvas-off-open');
  });

  // Scroll events / animations
  if ($('[data-scroll]').length) {
    const scrollElements = $('[data-scroll]');
    scrollElements.not('[data-scroll-class]').attr('data-scroll-class', 'animate-fade-in-up');

    scrollElements.scrollClass({
      offsetTop: -100,
      delay: 10,
      threshold: 30
    }).trigger('scroll');
  }

  $('.animate-svg svg, .animate-scroll, .animate-scroll-zoom').attr('data-scroll-class', 'animate-now').scrollClass({
    offsetTop: -100,
    delay: 10,
    threshold: 30,
    callback: function () {
      const el = $(this);
      window.setTimeout(function () {
        el.addClass('can-start-animation');
      }, 2000);
    }
  }).trigger('scroll');

  $('#steps .tabs').on('change.zf.tabs', function() {
    $('.step-tabs-content').find('.animate-now').each(function(){
      $(this).removeClass('animate-now');
    });

    window.setTimeout(function() {
      $('.step-tabs-content .is-active').find('.animate-svg svg').each(function(){
        $(this).addClass('animate-now');
      });
    }, 100);
  });

  // Trust Seals Slider
  $('.seals-slider').slick({
    arrows: false,
    slidesToShow: 1,
    cssEase: 'ease-out',
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000
  });

  // CTA Section Mobile Slider
  $('.seals-mobile-slider').slick({
    arrows: false,
    slidesToShow: 1,
    cssEase: 'ease-out',
    dots: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000
  });

  // Jumbotron text slider
  $('.jumbotron-carousel-vertical').slick({
    arrows: false,
    slidesToShow: 1,
    cssEase: 'ease-in-out',
    //fade: true,
    vertical: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnFocus: false,
    pauseOnHover: false,
    draggable: false
  });

  // Jumbotron slider
  $('.jumbotron-carousel').slick({
    arrows: false,
    slidesToShow: 1,
    fade: true,
    cssEase: 'ease-in-out',
    speed: 1000,
    autoplay: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    draggable: false
  });

  // Jumbotron visual slider
  $('.jumbotron-carousel-visuals').slick({
    arrows: false,
    slidesToShow: 1,
    asNavFor: '.jumbotron-carousel',
    cssEase: 'ease-in-out',
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    centerPadding: '25%',
    draggable: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: false
        }
      }
    ]
  });

  $('.trustpilot-slider').slick({
    dots: true,
    arrows: true,
    slidesToShow: 1,
    cssEase: 'ease-in-out',
    // vertical: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnFocus: false,
    pauseOnHover: false,
    draggable: false,
    infinite: true,
  });

  $('.afforestation-slider').slick({
    dots: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // vertical: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        centerMode: false
      }
    }]
  });

  $('#modal-begins-afforestation').on('open.zf.reveal', function() {
    $('.afforestation-slider').slick('refresh');
  });

  $(window).on('resize orientationchange', function() {
    $('.slick-initialized').slick('resize').slick('refresh');
  });

  // Hide & Show
  // -----------
  // mobile benefits details
  $('#show-mb-details')
    .bind('click', function () {
      $('#mobile-benefits .special-box-container').addClass('open');
  });

  $('#hide-mb-details')
    .bind('click', function () {
      $('#mobile-benefits .special-box-container').removeClass('open');
  });

  // conditions
  $('#hide-conditons')
    .hide()
    .bind('click', function () {
      $('#conditions').removeClass('open');
      $('#calculator2021 #mobile-conditions .conditions').removeClass('open');
      $(this).hide();
      $('#show-conditons').show();
      //$('.infotext-box').show();
  });

  $('#show-conditons')
    .bind('click', function () {
      $('#conditions').addClass('open');
      $('#calculator2021 #mobile-conditions .conditions').addClass('open');
      $(this).hide();
      $('#hide-conditons').show();
      //$('.infotext-box').hide();
  });

  // mobile conditions
  $('#hide-mobile-conditons')
    .hide()
    .bind('click', function () {
      $('#mobile-calculator .conditions').removeClass('open');
      $(this).hide();
      $('#show-mobile-conditons').show();
  });

  $('#show-mobile-conditons')
    .bind('click', function () {
      $('#mobile-calculator .conditions').addClass('open');
      $(this).hide();
      $('#hide-mobile-conditons').show();
  });


  // Show Subnavi on scroll up and hide on scroll down
  if ($('.top-bar').length) {
    var lastScroll = 0;
    $(window).scroll(function () {
      //Sets the current scroll position
      var st = $(this).scrollTop();
      //Down
      if (st > lastScroll){
        $('.top-bar-slim').addClass('top-bar-hide');
      }
      //Up
      else {
        $('.top-bar-slim').removeClass('top-bar-hide');
      }
      //Updates scroll position
      lastScroll = st;
    });
  }

  // Sticky Navi
  if ($('.multiproduct').length) {
    $(window).scroll(function () {
      if ($(document).scrollTop() >= 50) {
        $('.top-bar').addClass('top-bar-slim');
      } else {
        $('.top-bar').removeClass('top-bar-slim');
      }
    });
  }
  else {
    $(window).scroll(function () {
      if ($(document).scrollTop() >= 500) {
        $('.top-bar').addClass('top-bar-slim');
      } else {
        $('.top-bar').removeClass('top-bar-slim');
      }
    });
  }

  $(window).scroll(function () {
    if ($('.scroll-up').length) {
      if ($(document).scrollTop() >= 700) {
        $('.scroll-up').css('opacity', 1.0);
      } else {
        $('.scroll-up').css('opacity', 0.0);
      }
    }
  });

  // Sticky Top Teaser
  if ($('.top-teaser').length) {

    window.setTimeout(function () {
      $('.top-teaser').removeClass('top-teaser-hide');
      $(window).trigger('scroll');
    }, 500);

    $(window).scroll(function () {
      if ($(document).scrollTop() >= 300) {
        $('.top-teaser').addClass('top-teaser-hide');
      } else {
        $('.top-teaser').removeClass('top-teaser-hide');
      }
    });
  }

  $('body:has(#nfx-calculator-app) .nfx-calculator-button, #nfx-calculator-app .cta').removeAttr('onclick').click(function() {
    $('body').addClass('loading');
  });

  //Beer-Slider
  if ($('.beer-slider').length) {
    $('.beer-slider').each( function( index, el ) {
      $(el).BeerSlider({start: $(el).data('beer-start')});
    });

    $('.beer-range').bind('focus touchstart touchend', function() {
      $('.beer-slider').addClass('beer-start beer-interact');
    });

    $(window).ready(function(){
      $(this).one('scroll', function(){
        $('.beer-slider').addClass('beer-start');
      });
    });
  }

  $(function () {
    $('a[href*="#"]:not([href="#"]):not([href^="#panel"]):not([href^="#faq"]):not([href*="#deeplink1"]):not([href*="#lightbox"])').bind('click', function () {

      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        if (target.length && $('.js-mobile-scroll').length) {
          $('html, body').animate({
            scrollTop: target.offset().top - 85
          }, 400);
          // return false;
        }

        if ($('.off-canvas.is-open').length) {
          $('.off-canvas.is-open').foundation('close');
        }

      }
    });
  });

  var sliderB = $('#betragSlider');
  var sliderL = $('#laufzeitSlider');
  var sliderB2 = $('#betragSlider2');
  var sliderL2 = $('#laufzeitSlider2');

  sliderB.slider({
    range: 'min'
  });
  sliderL.slider({
    range: 'min'
  });
  sliderB2.slider({
    range: 'min'
  });
  sliderL2.slider({
    range: 'min'
  });

  $('body').addClass('fa-loaded loaded');

  if ($('#modal-survey').length) {

    // setTimeout(function () {
    //     $('.sticky-badge').addClass('in');
    // }, 2000);

    var intervalBadge = setInterval(function () {
        $('.sticky-badge img').toggleClass('jump');
    }, 5000);

    $('.sticky-badge').on('click', function() {
        clearInterval(intervalBadge);
    });

    // Activate on Scroll-Position
    $(window).scroll(function() {
        // var hT = $('#benefits').offset().top,
        var hH = $('#benefits').outerHeight(),
            wH = $(window).height(),
            wS = $(this).scrollTop();

        if (wS > (hH - wH)){
            // $('.top-bar').css({'background': 'red'});
            $('.sticky-badge').addClass('in');
        }
    });

  }

})(jQuery);
